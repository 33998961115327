import { render, staticRenderFns } from "./SearchSortBy.vue?vue&type=template&id=198c8152&scoped=true&"
import script from "./SearchSortBy.vue?vue&type=script&lang=ts&"
export * from "./SearchSortBy.vue?vue&type=script&lang=ts&"
import style0 from "./SearchSortBy.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./SearchSortBy.vue?vue&type=style&index=1&id=198c8152&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "198c8152",
  null
  
)

export default component.exports