





















































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  Ref,
  watch,
  nextTick,
} from '@vue/composition-api';
import { ProductAddToCartTranslations, Context } from '@vf/api-contract';
import { ProductInventoryState } from '@vf/api-client';
import {
  ApplePayContext,
  useCart,
  useGtm,
  useProduct,
  useUpsell,
  useAccount,
  useNotification,
  useAuthentication,
  ROUTES,
  useApplePay,
  useValidation,
  useSignInToStore,
  useNotifyMe,
  useMonetate,
} from '@vf/composables';
import { getAddToCartOverrideAttributes } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers';
import throttle from '@vf/shared/src/utils/helpers/throttle';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import NotifyMeModal from '../../modals/NotifyMeModal.vue';
import SaveToFavorites from '@/components/smart/pdp/SaveToFavorites.vue';
import ProductCustomize from '@/components/smart/pdp/ProductCustomize.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import {
  PdpShippingMethod,
  useCartStore,
} from '@vf/composables/src/store/cartStore';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';
import useSignInToBuy from '@/components/smart/shared/composables/useSignInToBuy';
import { EnhancedSaleList } from '@vf/composables/src/types/gtm';

const handleUpsellDispatchEvent = (
  isUpsellSelected: Ref<boolean>,
  upsellItem,
  contextKey: string,
  dispatchEvent: (event) => void
): void => {
  if (!isUpsellSelected.value) return;
  dispatchEvent({
    ...getEventFromTemplate('cart:add', {}),
    composablesContexts: { useProduct: contextKey },
    overrideAttributes: {
      productId: upsellItem.value.id,
      quantity: upsellItem.value.quantity,
      sku: upsellItem.value.sku,
      list: EnhancedSaleList.UP_SELL,
    },
  });
};

const themeConfigDefaults = {
  largeScreenClass: 'vf-button--medium',
  notifyClass: 'vf-button--secondary',
};

export default defineComponent({
  name: 'ProductAddToCart',
  components: {
    ProductCustomize,
    NotifyMeModal,
    SaveToFavorites,
  },
  props: {
    translations: {
      type: Object as PropType<ProductAddToCartTranslations>,
      required: true,
    },
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Prop to show add to favorites button with add to cart button */
    showAddToFavourites: {
      type: Boolean,
      default: false,
    },
    modals: {
      type: Object,
      default: () => ({
        signInToBuy: null,
        loyaltyEnrollment: null,
      }),
    },
  },
  emits: [
    'click-add-to-favorites',
    'click-find-in-store',
    'click-remove-from-favorites',
    'close-find-in-store-modal',
  ],
  setup(props) {
    const { root } = useRootInstance();
    const {
      setMiniCart,
      addItem,
      isAddItemRequestPending,
      getProductId,
    } = useCart(root);
    const {
      areAllAttributesSelected,
      attributesData,
      isAttributeOutOfStockStatus,
      isSelectedProductOutOfStock,
      isQuickShopContext,
      checkAttributes,
      scrollToFirstValidationError,
      product,
      productLength,
      productSize,
      productVariant,
      productWidth,
      productZip,
      checkNotifyMeEnabled,
    } = useProduct(root, props.contextKey);
    const { getSignInToBuyState } = useSignInToBuy(root, props.contextKey);
    const {
      isNotifyMeFormVisible,
      subscribe,
      subscriptionFeedback: notifyMeSubscriptionFeedback,
      reset: resetNotifyMe,
      isAlreadySubscribed,
    } = useNotifyMe(root, props.contextKey);
    const { employeeConnected } = useSignInToStore(root);
    const userStore = useUserStore(root);
    const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);
    const { setPendingActionForLoyaltyUser } = useAuthentication(root);
    const { dispatchEvent } = useGtm(root);
    const { upsellItem, isUpsellSelected } = useUpsell(root, props.contextKey);
    const { customerEmail, favoriteStoreId } = useAccount(root);
    const { addNotification, clearNotifications } = useNotification(root);
    const { openModal, closeModal, isModalOpen, additionalData } = useModal();
    const { extractRecommendedAction, monetateCartCustomVars } = useMonetate(
      root,
      props.contextKey
    );
    const cart = useCartStore();
    const {
      allowApplePayOrderOnPDP,
      isBopis20Enabled,
      isCheckoutRedesignEnabled,
      // TODO: GLOBAL15-63801 clean up
      isVansPdpRedesignEnabled,
      pdpShowApplePayAsPrimaryCta,
    } = useFeatureFlagsStore();

    const addToCartRef = ref(null);
    const isNotifyMeMessageVisible = ref(false);
    const themeConfig = {
      ...themeConfigDefaults,
      ...root.$themeConfig.productAddToCart,
    };
    const { isApplePayApiDefined } = useApplePay(root, ApplePayContext.PDP);
    const addToCartObserver = ref<IntersectionObserver>(null);
    const isAddToCartVisible = ref(false);

    // Do not show if employee is connected and config is true.
    const shouldShowAddToFavButton = computed(() => {
      return !(
        (employeeConnected.value &&
          root.$themeConfig?.saveToFavorites?.hideFavouriteCtaForEmployees) ??
        false
      );
    });

    const isNotifyMeEnabled = computed(checkNotifyMeEnabled);

    const isButtonLoading = computed(
      () => themeConfig.enableLoading && isAddItemRequestPending.value
    );

    const isSignInToBuy = computed(() =>
      getSignInToBuyState(product.value, loyaltyEnrolled.value)
    );

    const attributesNotValid = computed(() => checkAttributes());

    const scrollToError = () => {
      // TODO: GLOBAL15-63801 clean up
      const scrollToErrorOffset = !root.$viewport.isSmall
        ? themeConfig.scrollToErrorOffsetWithTopStickyHeader
        : themeConfig.scrollToErrorOffset;
      scrollToFirstValidationError(
        scrollToErrorOffset,
        root.$viewport.isSmall && isQuickShopContext.value
      );
    };

    const getCloseMiniCartDelay = () => {
      if (isCheckoutRedesignEnabled) return undefined;
      return themeConfig.closeMiniCartDelay || root.$viewport?.isSmall
        ? 6000
        : undefined;
    };

    const handleAfterAddToCartActions = () => {
      if (
        root.$viewport.isSmall &&
        ((isQuickShopContext.value &&
          themeConfig.quickshopScrollToTopAfterAddToCart) ||
          !isQuickShopContext.value)
      ) {
        scrollToTop();
      }
      if (!root.$route.path.endsWith(ROUTES.CART())) {
        setMiniCart(true, getCloseMiniCartDelay());
      }
    };

    const pdpShippingOverride = (overrideAttributes) => {
      if (cart.pdpShippingMethod === PdpShippingMethod.Pickup) {
        const productId = getProductId(product.value);
        const productInSTS = cart.stsItems.find(
          (item) => item.productId === productId
        );

        overrideAttributes = Object.assign({}, overrideAttributes, {
          shippingMethod: `BOPIS_PDP_${productInSTS ? 'STS' : 'SD'}`,
        });
      }
      return overrideAttributes;
    };

    const onSuccessfulAddToCart = (isAddedToCart) => {
      if (!isAddedToCart) return;
      let overrideAttributes = getAddToCartOverrideAttributes(
        product.value,
        root.$themeConfig
      );
      const experienceId = additionalData.value?.experienceId;
      if (experienceId) {
        const experience = extractRecommendedAction(experienceId);
        overrideAttributes = Object.assign({}, overrideAttributes, {
          experience,
          list_type: additionalData.value?.listType,
        });
      }
      if (monetateCartCustomVars.value) {
        overrideAttributes = Object.assign(
          {},
          overrideAttributes,
          monetateCartCustomVars.value
        );
      }
      overrideAttributes = pdpShippingOverride(overrideAttributes);
      dispatchEvent({
        ...getEventFromTemplate('cart:add', {}),
        composablesContexts: { useProduct: props.contextKey },
        overrideAttributes,
      });
      handleUpsellDispatchEvent(
        isUpsellSelected,
        upsellItem,
        props.contextKey,
        dispatchEvent
      );
      dispatchEvent(getEventFromTemplate('cart:update', {}));
      closeModal();
      handleAfterAddToCartActions();
    };

    const payloadToAddToCart = computed(() => {
      const payload = {
        ...product.value,
        ...(product.value.customsRecipeID && {
          precreatedCustomsCode: product.value.id,
        }),
        ...(cart.pdpShippingMethod === PdpShippingMethod.Pickup && {
          storeId: favoriteStoreId.value,
        }),
      };
      return isUpsellSelected.value ? [payload, upsellItem.value] : payload;
    });

    const validateAndAddToCart = async () => {
      clearNotifications();
      if (attributesNotValid.value) {
        scrollToError();
        return;
      }

      const isAddedToCart = await addItem(payloadToAddToCart.value, {
        isTemporary: false,
        ...(cart.pdpShippingMethod === PdpShippingMethod.Pickup && {
          queryParamsObject: {
            action: 'pickup',
            favStoreId: favoriteStoreId.value,
          },
        }),
      });
      if (product.value.customsRecipeID) {
        const payloadMainProduct = isUpsellSelected.value
          ? payloadToAddToCart.value[0]
          : payloadToAddToCart.value;
        product.value.sku = payloadMainProduct.sku;
      }
      onSuccessfulAddToCart(isAddedToCart);
    };

    const showStickyHeader = throttle(() => {
      const addToCartRect = addToCartRef.value?.getBoundingClientRect();
      const headerHeight = (document.getElementsByClassName(
        'vf-header__container'
      )[0] as HTMLElement)?.offsetHeight;
      // skip emitting events if referenced item does not reserve space vertically
      if (!addToCartRect || addToCartRect.width === 0) return;

      const showStickyHeader =
        !isAddToCartVisible.value &&
        document.documentElement.scrollTop > headerHeight;

      root.$eventBus.$emit('headerControlVisibility', showStickyHeader);
      root.$eventBus.$emit('showStickyHeader', showStickyHeader);
    }, 200);

    const productColor = computed(() => product.value?.color?.value);
    // clear notify me form when product size changes
    watch(
      [productColor, productSize, productZip, productLength, productWidth],
      () => {
        isNotifyMeFormVisible.value = false;
        isNotifyMeMessageVisible.value = false;
      }
    );

    const isButtonDeactivated = computed(() => {
      return (
        !isNotifyMeEnabled.value &&
        [
          isSelectedProductOutOfStock.value,
          !productColor.value,
          isAttributeOutOfStockStatus('size', product.value?.size?.value),
          isAttributeOutOfStockStatus('length', product.value?.length?.value),
          isAddItemRequestPending.value,
          product.value?.productInventoryState ===
            ProductInventoryState.SoldOut,
        ].some(Boolean)
      );
    });

    const buttonClasses = computed(() => {
      const classList = [];

      if (
        isQuickShopContext.value ||
        root.$viewport?.isSmall ||
        product.value?.dummyCustoms
      ) {
        classList.push('vf-button--fullWidth');
      } else {
        classList.push(themeConfig.largeScreenClass);
      }
      if (product.value?.variant?.stock?.inStock) {
        classList.push(themeConfig.activeClass);
      } else if (product.value && areAllAttributesSelected.value) {
        classList.push(themeConfig.notifyClass);
      }
      if (themeConfig.isSignInToBuyAtVariantLevel && isSignInToBuy.value) {
        classList.push('vf-button--xplr');

        props.contextKey === 'quickshop' &&
          classList.push('product-add-to-cart__sitb');
      }
      return classList;
    });

    const showNotifyMeForm = () => {
      isNotifyMeFormVisible.value = true;
      dispatchEvent(
        getEventFromTemplate('notify-me:click', {
          eventLabel: getGTMProductId(
            product.value,
            root.$themeConfig.gtm?.useColorOnId
          ),
        })
      );
    };

    const showProductCustomize = showProductCustomizeImp({
      isQuickShopContext,
      product,
    });

    const subscribeToProductUpdates = async () => {
      const { $v: $vSizeSelect } = useValidation(root, 'SIZE_SELECT');
      $vSizeSelect.value.$touch();
      if (!attributesData.value.isFutureProduct && $vSizeSelect.value.$error) {
        return;
      }
      const { $v: $vNotifyMe } = useValidation(root, 'NOTIFY_ME');
      $vNotifyMe.value.$touch();
      if ($vNotifyMe.value.$error || notifyMeSubscriptionFeedback.value.success)
        return;
      try {
        await subscribe(
          productVariant.value.id,
          !attributesData.value.isFutureProduct
        );
      } catch (error) {
        addNotification({
          message: props.translations.notificationErrorMessage,
          type: 'danger',
        });
      }
    };

    const getNotifyMeAction = () => {
      if (
        !attributesData.value.isFutureProduct &&
        props.contextKey !== Context.QuickShop
      ) {
        showNotifyMeForm();
      } else {
        subscribeToProductUpdates();
      }
    };

    // Manage Success label after add to cart complete
    const labelAddToCartSuccess = ref('');
    const startTransactionAddItem = ref(false);
    const addItemRequestHandler = (isPending: boolean) => {
      if (isPending) {
        startTransactionAddItem.value = true;
      } else if (startTransactionAddItem.value) {
        // in this case we are at the end of addItem transaction
        startTransactionAddItem.value = false;
        labelAddToCartSuccess.value = props.translations.buttonCTASuccess;
        setTimeout(() => {
          // reset label after 2s
          labelAddToCartSuccess.value = '';
        }, 2000);
      }
    };

    if (themeConfig.enableSuccessLabel) {
      watch(isAddItemRequestPending, (isPending: boolean) => {
        addItemRequestHandler(isPending);
      });
    }

    const buttonText = computed(() => {
      if (isNotifyMeEnabled.value) {
        return props.translations.buttonCTANotify;
      }
      if (labelAddToCartSuccess.value) {
        return labelAddToCartSuccess.value;
      }
      if (
        product.value?.productInventoryState === ProductInventoryState.SoldOut
      ) {
        return props.translations.soldOutCta;
      }
      const outOfStockButtonText =
        isNotifyMeEnabled.value && !isButtonDeactivated.value
          ? props.translations.buttonCTANotify
          : props.translations.buttonCTADiscontinued;

      return isAddItemRequestPending.value || !isSelectedProductOutOfStock.value
        ? props.translations.buttonCTAActive
        : outOfStockButtonText;
    });

    const modalResourceId = computed(() =>
      loggedIn.value
        ? props.modals[themeConfig.modal?.loggedIn || 'loyaltyEnrollment']
        : props.modals[themeConfig.modal?.notLoggedIn || 'signInToBuy']
    );

    const openSignInToBuyModal = async () => {
      clearNotifications();
      if (attributesNotValid.value) {
        scrollToError();
        return;
      }
      if (isQuickShopContext.value) {
        closeModal();
      } else {
        setPendingActionForLoyaltyUser(async () => {
          if (loyaltyEnrolled.value) {
            await validateAndAddToCart();
          }
        });
        const unwatchIsModalOpen = watch(isModalOpen, () => {
          if (!isModalOpen.value) {
            unwatchIsModalOpen();
            setPendingActionForLoyaltyUser(null);
          }
        });
      }
      await nextTick();
      openModal({
        type: 'lazyFragment',
        resourceId: modalResourceId.value,
      });
      root.$eventBus.$emit(
        'pDpQuickshopSignInToBuyEmit',
        payloadToAddToCart.value
      );
    };

    const onPDPStickyEmited = ({ notifyMe } = { notifyMe: false }) => {
      onPDPStickyEmitedImp({
        getNotifyMeAction,
        isSignInToBuy,
        notifyMe,
        openSignInToBuyModal,
        validateAndAddToCart,
      });
    };

    const availableSizes = computed(() => {
      if (!product.value) return [];
      let sizes = (product.value.attributes || []).find(
        (attribute) => attribute.code === 'size'
      );
      return sizes?.options || [];
    });

    const showApplePay = computed(() => {
      /** Check feature flags relating to Apple Pay */
      if (!allowApplePayOrderOnPDP || !pdpShowApplePayAsPrimaryCta)
        return false;

      /** ApplePay API check */
      if (!isApplePayApiDefined()) {
        return false;
      }

      /** Out of Stock check */
      if (isSelectedProductOutOfStock.value) {
        return false;
      }

      /** Product custom check */
      return !(
        product.value?.customsRecipeID ||
        product.value?.recipe ||
        product.value?.recipeId
      );
    });

    onMounted(async () => {
      document.addEventListener('scroll', showStickyHeader);
      root.$eventBus.$on('pDpStickyEmit', onPDPStickyEmited);

      addToCartObserver.value = new IntersectionObserver(
        (entries) =>
          (isAddToCartVisible.value = entries.some(
            ({ isIntersecting }) => isIntersecting
          )),
        {
          rootMargin: `-${themeConfig.scrollToErrorOffsetWithTopStickyHeader}px 0px 0px 0px`,
        }
      );

      if (addToCartRef.value) {
        addToCartObserver.value.observe(addToCartRef.value);
      }
    });

    onBeforeUnmount(() => {
      document.removeEventListener('scroll', showStickyHeader);
      root.$eventBus.$off('pDpStickyEmit', onPDPStickyEmited);
      resetNotifyMe();

      if (addToCartObserver.value && addToCartRef.value) {
        addToCartObserver.value.unobserve(addToCartRef.value);
      }
    });

    return {
      addToCartRef,
      allowApplePayOrderOnPDP,
      attributesData,
      availableSizes,
      buttonClasses,
      buttonText,
      customerEmail,
      getNotifyMeAction,
      handleAfterAddToCartActions,
      isAlreadySubscribed,
      isBopis20Enabled,
      isButtonDeactivated,
      isButtonLoading,
      isNotifyMeEnabled,
      isNotifyMeFormVisible,
      isNotifyMeMessageVisible,
      isQuickShopContext,
      isSignInToBuy,
      isVansPdpRedesignEnabled,
      onSuccessfulAddToCart,
      openSignInToBuyModal,
      pdpShowApplePayAsPrimaryCta,
      product,
      productColor,
      productLength,
      productSize,
      productWidth,
      productZip,
      shouldShowAddToFavButton,
      showApplePay,
      showStickyHeader,
      showProductCustomize,
      useApplePay,
      validateAndAddToCart,
    };
  },
});

const getGTMProductId = (product, useColorOnId) => {
  const color = product.color || product.colors?.[0];
  const colorCode = color.value;
  return useColorOnId ? product.id + colorCode : product.id;
};

// showProductCustomize implementation
const showProductCustomizeImp = ({ isQuickShopContext, product }) =>
  computed(() => !isQuickShopContext.value && product.value?.dummyCustoms);

// onPDPStickyEmited implementation
const onPDPStickyEmitedImp = ({
  getNotifyMeAction,
  isSignInToBuy,
  notifyMe,
  openSignInToBuyModal,
  validateAndAddToCart,
}) => {
  if (notifyMe) getNotifyMeAction();
  else {
    isSignInToBuy.value ? openSignInToBuyModal() : validateAndAddToCart();
  }
};
