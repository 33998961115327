








































import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';

import { apiClientFactory } from '@vf/api-client';
import { useCart } from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';

import AddressPreview from '@/components/static/AddressPreview.vue';
import CheckoutAddress from '@/components/static/addressBook/CheckoutAddress.vue';
import useRootInstance from '@/shared/useRootInstance';

enum BillingAddress {
  SAME_AS_SHIPPING = 'same-as-shipping',
  DIFFERENT_ADDRESS = 'add-different-address',
}

export default defineComponent({
  name: 'BillingAddressSelector',
  components: {
    AddressPreview,
    CheckoutAddress,
  },
  setup() {
    const { root } = useRootInstance();
    const { updateCart } = useCart(root);
    const { setBillingAddress } = apiClientFactory(root);
    const cartStore = useCartStore();
    const {
      billingAddressRadio,
      isBillingFormEmpty,
      billingFormEditMode,
    } = storeToRefs(useCheckoutStore());
    const { cartId, shippingAddress, billingAddress } = storeToRefs(cartStore);

    const checkoutAddressRef = ref(null);

    const sameAsShipping = computed(() => {
      if (!shippingAddress.value) return false;
      return billingAddressRadio.value === BillingAddress.SAME_AS_SHIPPING;
    });

    const validateAndSaveBillingAddress = async (): Promise<boolean> => {
      if (sameAsShipping.value) {
        try {
          const response = await setBillingAddress(
            cartId.value,
            shippingAddress.value
          );
          if (response.status === 200) {
            await updateCart(response.data);
          }
        } catch (err) {
          root.$log.error(
            '[@theme/components/static/checkout/BillingAddressSelector::validateAndSaveBillingAddress]',
            err.message
          );
          return false;
        }
        return true;
      }

      return await checkoutAddressRef.value.submit();
    };

    const billingAddressIsSameAsShipping = computed(
      () =>
        shippingAddress.value &&
        Object.keys(billingAddress.value).reduce(
          (acc, key) =>
            acc && shippingAddress.value[key] === billingAddress.value[key],
          true
        )
    );

    const handleBillingFormEdit = (address) => {
      if (!address) return;

      isBillingFormEmpty.value = ![
        address.firstName,
        address.lastName,
        address.addressLine1,
        address.city,
        address.province,
        address.postalCode,
        address.phone,
        address.email,
      ].some(Boolean);
    };

    const onDifferentAddressSelected = () => {
      // in case we have the billing address already saved in the store,
      // and that address is the same as the shipping address,
      // when the user selects "new shipping address", we invalidate the store information to show a blank form
      if (billingAddressIsSameAsShipping.value) {
        billingAddress.value.addressId = '';
        billingAddress.value.addressLine1 = '';
      }
    };

    watch(
      billingAddress,
      (newBillingAddress) => {
        billingAddressRadio.value = billingAddressIsSameAsShipping.value
          ? BillingAddress.SAME_AS_SHIPPING
          : BillingAddress.DIFFERENT_ADDRESS;
        handleBillingFormEdit(newBillingAddress);
      },
      { immediate: true }
    );

    onMounted(() => {
      billingAddressRadio.value =
        !shippingAddress.value ||
        (!billingAddressIsSameAsShipping.value &&
          billingAddress.value.addressLine1)
          ? BillingAddress.DIFFERENT_ADDRESS
          : BillingAddress.SAME_AS_SHIPPING;
    });

    onBeforeUnmount(() => {
      isBillingFormEmpty.value = false;
      checkoutAddressRef.value = null;
    });

    return {
      BillingAddress,
      billingAddressRadio,
      checkoutAddressRef,
      shippingAddress,
      billingAddress,
      onDifferentAddressSelected,
      sameAsShipping,
      validateAndSaveBillingAddress,
      handleBillingFormEdit,
      billingFormEditMode,
    };
  },
});
