//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from 'vue-click-outside';
import { onMounted, ref, computed, watch } from '@vue/composition-api';
import LoyaltyRewardsProgressBar from './LoyaltyRewardsProgressBar.vue';
import LoyaltyRewardsExpiry from './LoyaltyRewardsExpiry.vue';
import useRootInstance from '@/shared/useRootInstance';

export default {
  name: 'LoyaltyRewardsProgressDialog',
  components: {
    LoyaltyRewardsProgressBar,
    LoyaltyRewardsExpiry,
  },
  directives: {
    ClickOutside,
  },
  props: {
    greeting: {
      type: String,
      default: '',
    },
    totalRewards: {
      type: String,
      default: '',
    },
    summary: {
      type: String,
      default: '',
    },
    vouchers: {
      type: Array,
      default: () => [],
    },
    full: {
      type: Boolean,
      default: false,
    },
    rewards: {
      type: Object,
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const dialog = ref(null);

    const hasExpiringPoints = computed(
      () =>
        props.rewards &&
        props.rewards.expiringLoyaltyPoints &&
        props.rewards.expiringLoyaltyPointsDays <= 30
    );

    const expiringPointsLabel = computed(() => {
      const points = root.$t('loyaltyRewardsExpiry.points', {
        points: props.rewards.expiringLoyaltyPoints,
      });
      const expiration = root.$t('loyaltyRewardsExpiry.expireWithin', {
        days: props.rewards.expiringLoyaltyPointsDays,
      });
      return `${points} ${expiration}`;
    });

    const showDialog = () => {
      dialog.value.show();
      setTimeout(() => {
        dialog.value.close();
      }, 5000);
    };

    onMounted(() => {
      const unwatchExpiringPoints = watch(hasExpiringPoints, (newValue) => {
        newValue && showDialog();
        unwatchExpiringPoints();
      });
      root.$eventBus.$on('simplified-enrollment-login', showDialog);
    });

    return {
      dialog,
      expiringPointsLabel,
      hasExpiringPoints,
    };
  },
};
