

















































































import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import {
  useCheckout,
  useKlarna,
  useNotification,
  usePaymentMethods,
  useSignInToStore,
} from '@vf/composables';
import { CheckoutContext } from '@vf/api-contract';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
import { usePayments } from '../../smart/checkout/CheckoutPaymentInfo/composable/usePayments';

import PaymentInformation from '@/components/static/checkout/PaymentInformation.vue';
import CheckoutGiftCard from '@/components/static/checkout/CheckoutGiftCard.vue';
import CheckoutAthlete from '@/components/static/checkout/CheckoutAthlete.vue';
import BillingAddressSelector from '@/components/static/checkout/BillingAddressSelector.vue';
import CheckoutReward from '@/components/smart/checkout/CheckoutRewardCard/CheckoutRewardCardNew.vue';
import PlaceOrderButton from '@/components/static/checkout/PlaceOrderButton.vue';
import OrderSummaryCollapsible from '@/components/static/OrderSummaryCollapsible.vue';

import useLoader from '@/shared/useLoader';
import useRootInstance from '@/shared/useRootInstance';
import { scrollTo } from '@vf/shared/src/utils/helpers';
import { usePaymentProvider } from '@vf/composables/src/usePaymentProvider';
import { useCardStore } from '@vf/composables/src/store/card';

export default defineComponent({
  name: 'PaymentStepComponents',
  components: {
    StoreEmployeeInfo: () =>
      import('@/components/static/checkout/StoreEmployeeInfo.vue'),
    BuyInStoreInfo: () =>
      import('@/components/static/checkout/BuyInStoreInfo.vue'),
    PaymentInformation,
    CheckoutGiftCard,
    CheckoutAthlete,
    BillingAddressSelector,
    CheckoutReward,
    PlaceOrderButton,
    OrderSummaryCollapsible,
  },
  props: {
    isPlaceOrderInProgress: {
      type: Boolean,
    },
  },
  emits: ['click:save-payment-step'],
  setup(_, { emit }) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const { setPaymentMethod, paymentMethod, getPaymentMethods } = useCheckout(
      root
    );
    const { paymentMethodHandlers } = usePaymentMethods(root);
    const { visiblePaymentMethods: paymentMethods } = usePayments();
    const {
      cartItems,
      isZeroOrder,
      appliedGiftCards,
      appliedRewardCards,
      appliedRewardCodes,
      appliedPointRewards,
      athleteDiscounts,
    } = storeToRefs(useCartStore());
    const {
      isBillingFormEmpty,
      billingFormEditMode,
      billingAddressRadio,
    } = storeToRefs(useCheckoutStore());
    const cardStore = useCardStore();
    const {
      isPointsToCurrencyEnabled,
      checkoutOrderSummaryUpdated,
      checkoutSkipReviewStep,
      checkoutStickyCtas,
      checkoutRegisteredUserSingleStep,
      configFamilyVouchers,
    } = useFeatureFlagsStore();
    const userStore = useUserStore(root);
    const {
      employeeConnected,
      hasStoreCookie,
      storeInfoData,
    } = useSignInToStore(root);
    const theme = root.$themeConfig.staticCheckout;

    const ctaContainer = ref(null);

    const isFamilyRewardsVisible = computed(
      () =>
        isPointsToCurrencyEnabled &&
        configFamilyVouchers.showOnPaymentStep &&
        userStore.loyaltyEnrolled
    );

    const isGiftCardFormVisible = computed(() => {
      return !isZeroOrder.value || appliedGiftCards.value.length;
    });

    const isAthleteDiscountVisible = computed(() => {
      return userStore.athlete;
    });

    const initPaymentMethods = async () => {
      await getPaymentMethods();
      const hasPaymentMethod = paymentMethods.value.some(
        ({ code }) => code === paymentMethod.value
      );

      if (!paymentMethod.value || !hasPaymentMethod) {
        setPaymentMethod(PaymentMethodCode.CREDIT_CARD);
      }
    };

    const updatePaymentMethod = async (methodCode: PaymentMethodCode) => {
      setPaymentMethod(methodCode);
      await paymentMethodHandlers[methodCode].handler?.init?.();
    };

    onMounted(() => {
      initPaymentMethods();
    });

    const billingAddressSelectorRef = ref(null);
    const paymentInformationRef = ref(null);
    const buyInStoreInfoRef = ref(null);

    const showBillingAddressSelector = computed(() => {
      return !cardStore.card || (cardStore.card && !cardStore.card.address);
    });

    watch(
      [
        () => appliedGiftCards.value.length,
        () => appliedRewardCards.value.length,
        () => appliedRewardCodes.value.length,
        () => appliedPointRewards.value.length,
        () => athleteDiscounts.value.length,
      ],
      initPaymentMethods
    );

    const isOrderFullyCovered = (): boolean => {
      const applicableInstruments = {
        [PaymentMethodCode.GIFT_CARD]: appliedGiftCards.value,
        [PaymentMethodCode.REWARD_CARD]: appliedRewardCards.value,
        [PaymentMethodCode.REWARD_CODE]: appliedRewardCodes.value,
        [PaymentMethodCode.LOYALTY_POINTS]: appliedPointRewards.value,
        [PaymentMethodCode.ATHLETES]: athleteDiscounts.value,
      };

      if (isZeroOrder.value) {
        let setMethod = null;
        for (const [code, items] of Object.entries(applicableInstruments)) {
          if (items.length) {
            setPaymentMethod(code);
            setMethod = code;
            break;
          }
        }
        !setMethod && setPaymentMethod(PaymentMethodCode.ZERO_ORDER); // covered by promo codes and other promotions
        return true;
      } else {
        if (
          Object.keys(applicableInstruments)
            .concat([PaymentMethodCode.ZERO_ORDER])
            .includes(paymentMethod.value)
        ) {
          // if one of the zero order payment methods is selected
          // and cart does not constitute a zero order anymore,
          // let's reset to credit card
          setPaymentMethod(PaymentMethodCode.CREDIT_CARD);
        }
        return false;
      }
    };

    const getIsBuyInStoreInfoValid = () => {
      if (
        buyInStoreInfoRef.value &&
        hasStoreCookie.value &&
        storeInfoData.value
      )
        return buyInStoreInfoRef.value.submit();
      return true;
    };

    const gotoPaymentStep = (canGotoPaymentStep: boolean) => {
      if (!canGotoPaymentStep) return hideSpinner();

      if (checkoutSkipReviewStep) {
        isProcessing.value = false;
      }

      emit('click:save-payment-step');
    };

    const isContinueDisabled = computed(
      () =>
        !cartItems.value.length ||
        isProcessing.value ||
        ((checkoutSkipReviewStep || checkoutStickyCtas) &&
          root.$viewport.isSmall &&
          billingAddressRadio.value === 'add-different-address' &&
          billingFormEditMode.value &&
          isBillingFormEmpty.value)
    );

    const isProcessing = ref(false);

    const onSaveAndContinueClick = async () => {
      /**
       * Before proceeding to Review Step, check if order is fully covered
       * by any of the applicable payment instruments and set appropriate
       * payment method.
       */
      useNotification(root).clearNotifications();
      isProcessing.value = true;

      const isBuyInStoreInfoValid = getIsBuyInStoreInfoValid();

      if (isOrderFullyCovered()) {
        return gotoPaymentStep(isBuyInStoreInfoValid);
      }

      showSpinner();

      if (paymentMethod.value === PaymentMethodCode.CREDIT_CARD) {
        /**
         * Use existing Credit Card from account
         */
        if (cardStore.card) {
          const isValid: boolean = await billingAddressSelectorRef.value.validateAndSaveBillingAddress();
          if (!isValid) {
            isProcessing.value = false;
            return hideSpinner();
          }

          return gotoPaymentStep(isBuyInStoreInfoValid);
        }

        /**
         * Validates new entered Credit Card and billing address
         */
        const response = await usePaymentProvider(root).preparePaymentData();
        const isValid: boolean = await billingAddressSelectorRef.value.validateAndSaveBillingAddress();

        /**
         * Scrolls to Credit Card fields if they are empty (wait until billing address validation is finished)
         */
        if (!response) {
          root.$nextTick(() => {
            scrollTo({ top: paymentInformationRef.value.$el.offsetTop });
          });
        }

        /**
         * Hide spinner if Credit Card or billing address are incorrect
         */
        if (!isValid || !response) {
          isProcessing.value = false;
          return hideSpinner();
        }
      }

      /**
       * Validates Billing Address for Klarna payment
       */

      if (paymentMethod.value === PaymentMethodCode.KLARNA) {
        await useKlarna(root).getKlarnaToken();
        const isValid: boolean = await billingAddressSelectorRef.value.validateAndSaveBillingAddress();
        if (!isValid) {
          isProcessing.value = false;
          return hideSpinner();
        }
      }

      return gotoPaymentStep(isBuyInStoreInfoValid);
    };

    const isSaveAndContinueButtonVisible = computed(
      () =>
        ![PaymentMethodCode.PAYPAL, PaymentMethodCode.APPLEPAY].includes(
          paymentMethod.value
        )
    );

    onBeforeUnmount(() => {
      billingAddressSelectorRef.value = null;
    });

    return {
      billingAddressSelectorRef,
      buyInStoreInfoRef,
      cartItems,
      CheckoutContext,
      checkoutOrderSummaryUpdated,
      checkoutRegisteredUserSingleStep,
      checkoutSkipReviewStep,
      checkoutStickyCtas,
      ctaContainer,
      employeeConnected,
      hasStoreCookie,
      isAthleteDiscountVisible,
      isContinueDisabled,
      isFamilyRewardsVisible,
      isGiftCardFormVisible,
      isProcessing,
      isSaveAndContinueButtonVisible,
      isZeroOrder,
      onSaveAndContinueClick,
      OrderSummaryCollapsible,
      paymentInformationRef,
      paymentMethod,
      PaymentMethodCode,
      paymentMethods,
      showBillingAddressSelector,
      storeInfoData,
      theme,
      updatePaymentMethod,
    };
  },
});
