










































import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import OrderSummarySidebar from '@/components/static/OrderSummarySidebar.vue';
import { CheckoutContext } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import {
  computed,
  ref,
  Ref,
  watch,
  onBeforeUnmount,
  onUpdated,
} from '@vue/composition-api';

export default {
  name: 'OrderSummaryCollapsible',
  components: { OrderSummarySidebar },
  props: {
    currentStep: {
      type: String as PropType<CheckoutContext>,
      default: CheckoutContext.Cart,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { currency, totals } = storeToRefs(useCartStore());
    const isToggled = ref(false);
    const accordionHeader = computed(() =>
      isToggled.value
        ? root.$t('orderSummarySidebar.cart.hideOrderSummary')
        : root.$t('orderSummarySidebar.cart.viewOrderSummary')
    );
    const checkoutHeader = ref() as Ref<HTMLElement>;
    const cartHeader = ref() as Ref<HTMLElement>;

    onUpdated(() => {
      checkoutHeader.value = document.querySelector('.vf-checkout-header');
      cartHeader.value = document.querySelector(
        '.static-layout-header-megamenu'
      );
    });

    watch(isToggled, (isToggled) => {
      if (isToggled) {
        cartHeader.value?.style.setProperty('z-index', '1');
        checkoutHeader.value?.style.setProperty('display', 'none');
      } else {
        cartHeader.value?.style.removeProperty('z-index');
        checkoutHeader.value?.style.removeProperty('display');
      }
    });

    onBeforeUnmount(() => {
      cartHeader.value?.style.removeProperty('z-index');
      checkoutHeader.value?.style.removeProperty('display');
    });

    return {
      accordionHeader,
      CheckoutContext,
      currency,
      isToggled,
      totals,
    };
  },
};
