



import { Context } from '@vf/api-contract';
import {
  computed,
  defineComponent,
  onMounted,
  watch,
} from '@vue/composition-api';
import { load } from '@vf/shared/src/utils/helpers/load-script';
import useRootInstance from '@/shared/useRootInstance';
import { useProduct } from '@vf/composables';

type NarvarEddConfig = {
  localeCountry: string;
  localeLanguage: string;
  originZip: string;
  originCountry: string;
  scriptSrc: string;
};

export default defineComponent({
  name: 'NarvarEdd',
  setup() {
    const { root } = useRootInstance();
    const { productColor, productVariant } = useProduct(
      root,
      Context.PageContent
    );
    const config = root.$getConfigValueByCurrentLocale<NarvarEddConfig>(
      'NARVAR_EDD_ON_PDP'
    );

    const originZip = computed(() => {
      if (productVariant.value && productVariant.value.shipFromLocation)
        return productVariant.value.shipFromLocation;
      if (productColor.value && productColor.value.shipFromLocation)
        return productColor.value.shipFromLocation;
      return config.originZip;
    });

    const triggerEddCalculator = () => {
      window.narvar('eddCalculator', {
        originZip: originZip.value,
        originCountry: config.originCountry,
        localeLanguage: config.localeLanguage,
        localeCountry: config.localeCountry,
      });
    };

    onMounted(async () => {
      await load(config.scriptSrc);
      triggerEddCalculator();
    });

    watch(productVariant, () => {
      triggerEddCalculator();
    });

    return {};
  },
});
