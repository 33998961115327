





































import { defineComponent, computed } from '@vue/composition-api';
import { useSearch } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ProductsListNoResults',
  components: {
    VfShippingFilter: () => import('@vf/ui/components/Atom.ShippingFilter.vue'),
  },
  props: {
    /** True if provided content should be rendered as HTML. False for normal text */
    html: {
      type: Boolean,
      default: false,
    },
    /** Title text or RichText object html */
    title: {
      type: String,
      default: 'No results',
    },
    /** Subtitle text */
    subtitle: {
      type: String,
      default: 'We did not find any products.',
    },
    /** Title styles object */
    titleStyles: {
      type: Object,
      default: () => ({
        color: '',
        fontSize: '',
        fontFamily: '',
        fontWeight: '',
      }),
    },
    /** SubTitle styles object */
    subtitleStyles: {
      type: Object,
      default: () => ({
        color: '',
        fontSize: '',
        fontFamily: '',
        fontWeight: '',
      }),
    },
    /** True if component is used in PLP */
    isPlp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { pagination } = useSearch(root);
    const { isBopis20PlpEnabled } = useFeatureFlagsStore();

    const isEmpty = computed(() => props.isPlp || pagination.value.total === 0);
    const showShippingFilter = computed(
      () =>
        root.$themeConfig.plpGrid.showShippingFilter &&
        isBopis20PlpEnabled &&
        isEmpty.value
    );
    return {
      isEmpty,
      showShippingFilter,
    };
  },
});
